import Pristine from 'pristinejs'
import { useHttp } from '@utils/useHttp'
import { localeDatepicker } from '@utils/localeDatepicker'
import Layout from '@core/Layout'
import { queryParams } from '@utils/queryParams'
import { Spinner } from '@components/Spinner'
import { useNotifications } from '@utils/useNotifications'
import { NOTIFICATION_ICON_MAPPER } from './consts'

export default class Event extends Layout {
  constructor() {
    super({
      selector: '.event-create',
    })

    this.pristine = null
    this.$form = this.$target.find('form')
    this.$startDate = this.$target.find('#event_startDate')
    this.$endDate = this.$target.find('#event_endDate')

    this.bindEvents()
  }

  bindEvents() {
    this.$target.find('#event_type').select2({
      width: '100%',
    })

    this.$target.find('#event_submit').on('click', (e) => {
      e.preventDefault()
      const $this = $(e.target)

      if (this.$form.length) {
        if (this.pristine && this.pristine.validate()) {
          const formData = new FormData(this.$form.get(0))

          const formattedStartData = moment(this.$startDate.datepicker('getDate')).format('DD/MM/YYYY')
          const formattedEndData = moment(this.$endDate.datepicker('getDate')).format('DD/MM/YYYY')

          formData.set('event[startDate]', formattedStartData)
          formData.set('event[endDate]', formattedEndData)

          let routeUrl = Routing.generate('app_ajax_event_create', { teamId: userTeamId })

          if ($this.data('event-id')) {
            routeUrl = Routing.generate('app_ajax_event_update', {
              teamId: userTeamId,
              eventId: $this.data('event-id'),
            })
          }

          $.ajax({
            url: routeUrl,
            type: 'POST',
            data: formData,
            dataType: 'JSON',
            cache: false,
            contentType: false,
            processData: false,
            beforeSend: function () {
              Spinner($this).prepend()
              $this.prop('disabled', true)
            },
            success: function (response) {
              if (response.success) {
                if (response.redirect) {
                  window.location.href = response.redirect
                }
              } else {
                Spinner($this).remove()
                $this.prop('disabled', false)

                useNotifications('error', response.message)
              }
            },
          })
        }
      }

      return false
    })

    const $headerCalendarBox = this.$document.find('#header-calendar-box')
    const $notificationDates = $headerCalendarBox.find('.notification-date')

    if ($notificationDates.length) {
      const $notificationDatesArray = $notificationDates.toArray()

      $notificationDatesArray.forEach((el) => {
        const dataNotificationCreatedAt = $(el).data('notification-created-at')

        if (dataNotificationCreatedAt) {
          $(el).text(moment.unix(dataNotificationCreatedAt).fromNow())
        }
      })
    }

    $headerCalendarBox.find('.notifications-actions .mark-all').on('click', async (e) => {
      e.preventDefault()
      e.stopPropagation()

      const $this = $(e.currentTarget)
      const $notificationsBox = $headerCalendarBox.find('.scrollable')
      const query = { limit: 20 }

      $notificationsBox.css({ minHeight: $notificationsBox.height() })
      $notificationsBox.find('.event-row:not(.user)').remove()

      this.toggleLoader($notificationsBox, true)
      this.toggleDisabled($this, true)

      try {
        await this.hideAllNotifications()

        const { notifications } = await this.fetchNotifications(query)

        this.toggleLoader($notificationsBox, false)
        this.toggleDisabled($this, false)

        if (notifications.length) {
          notifications.forEach((item, i) => {
            $notificationsBox.append(`
            <div class="event-row" data-notification-id="${item.id}">
              <span class="close-icon">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.64645 1.22855C9.84171 1.03329 9.84171 0.716708 9.64645 0.521446L9.47855 0.353553C9.28329 0.158291 8.96671 0.158291 8.77145 0.353553L5.35355 3.77145C5.15829 3.96671 4.84171 3.96671 4.64645 3.77145L1.22855 0.353553C1.03329 0.158291 0.716708 0.158291 0.521446 0.353553L0.353553 0.521447C0.158291 0.716709 0.158291 1.03329 0.353553 1.22855L3.77145 4.64645C3.96671 4.84171 3.96671 5.15829 3.77145 5.35355L0.353553 8.77145C0.158291 8.96671 0.158291 9.28329 0.353553 9.47855L0.521447 9.64645C0.716709 9.84171 1.03329 9.84171 1.22855 9.64645L4.64645 6.22855C4.84171 6.03329 5.15829 6.03329 5.35355 6.22855L8.77145 9.64645C8.96671 9.84171 9.28329 9.84171 9.47855 9.64645L9.64645 9.47855C9.84171 9.28329 9.84171 8.96671 9.64645 8.77145L6.22855 5.35355C6.03329 5.15829 6.03329 4.84171 6.22855 4.64645L9.64645 1.22855Z" fill="#919191"/>
                </svg>
              </span>
              <a class="employee-outer-box" href="${Routing.generate('app_task_show', { teamId, id: item.taskId })}">
                <div class="message-icon">
                  <span class="${NOTIFICATION_ICON_MAPPER[item.eventType || '1']}"></span>
                </div>
                <div class="message-text">
                    ${!item.isSeen ? `<span class="task-mark pink">${Translator.trans('global.new')}</span>` : ''}
                    ${item.message}
                    <div class="notification-date">${moment.unix(item.createdAt).fromNow()}</div>
                </div>
              </a>
            </div>
          `)
          })

          $notificationsBox.css({ minHeight: $notificationsBox.height() })

          bindHeaderCalendarBox()
        }
      } catch (error) {} //eslint-disable-line
    })

    $headerCalendarBox.find('.notifications-actions .clear-all').on('click', async (e) => {
      e.preventDefault()
      e.stopPropagation()

      const $this = $(e.currentTarget)
      const $notificationsBox = $headerCalendarBox.find('.scrollable')

      $notificationsBox.css({ minHeight: $notificationsBox.height() })
      $notificationsBox.find('.event-row:not(.user)').remove()

      this.toggleLoader($notificationsBox, true)
      this.toggleDisabled($this, true)

      try {
        await this.removeAllNotifications()
        $headerCalendarBox.find('.calendar-active').switchClass('calendar-active', 'calendar')
        $headerCalendarBox
          .find('.online-users-box')
          .empty()
          .html(`<div class="no-events-row">${Translator.trans('events.no-events-for-approval')}</div>`)

        this.toggleLoader($notificationsBox, false)
        this.toggleDisabled($this, false)
      } catch (error) {} //eslint-disable-line
    })

    const bindHeaderCalendarBox = () => {
      $headerCalendarBox.find('.event-row:not(.user)').on('click', async (e) => {
        const $this = $(e.currentTarget)

        if (e.target.classList.contains('close-icon') || $(e.target).closest('.close-icon').length) {
          try {
            await this.removeNotification({ id: $this.data('notification-id') })
            $this.remove()
          } catch (error) {} //eslint-disable-line
        } else {
          try {
            await this.hideNotification({ id: $this.data('notification-id') })
            window.location = $this.find('.employee-outer-box').attr('href')
          } catch (error) {} //eslint-disable-line
        }
      })
    }

    bindHeaderCalendarBox()

    $headerCalendarBox.find('.load-more-notifications-btn').on('click', async (e) => {
      e.preventDefault()
      e.stopPropagation()
      const $this = $(e.currentTarget)
      const $notificationsBox = $headerCalendarBox.find('.scrollable')
      const query = { limit: 20 }

      if ($notificationsBox.find('.event-row').length) {
        query.fromId = $notificationsBox.find('.event-row:last').data('notification-id')
      }

      $notificationsBox.css({ minHeight: $notificationsBox.height() })

      this.toggleLoader($notificationsBox, true)
      this.toggleDisabled($this, true)

      try {
        const { notifications, total } = await this.fetchNotifications(query)

        this.toggleLoader($notificationsBox, false)
        this.toggleDisabled($this, false)

        if (notifications.length) {
          //   $headerCalendarBox.find('.calendar-active').switchClass('calendar-active', 'calendar')
          //   $headerCalendarBox.find('.online-users-box').empty().html(`<div class="no-events-row">${Translator.trans('events.no-events-for-approval')}</div>`)
          // } else {
          notifications.forEach((item, i) => {
            $notificationsBox.append(`
            <div class="event-row" data-notification-id="${item.id}">
              <span class="close-icon">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.64645 1.22855C9.84171 1.03329 9.84171 0.716708 9.64645 0.521446L9.47855 0.353553C9.28329 0.158291 8.96671 0.158291 8.77145 0.353553L5.35355 3.77145C5.15829 3.96671 4.84171 3.96671 4.64645 3.77145L1.22855 0.353553C1.03329 0.158291 0.716708 0.158291 0.521446 0.353553L0.353553 0.521447C0.158291 0.716709 0.158291 1.03329 0.353553 1.22855L3.77145 4.64645C3.96671 4.84171 3.96671 5.15829 3.77145 5.35355L0.353553 8.77145C0.158291 8.96671 0.158291 9.28329 0.353553 9.47855L0.521447 9.64645C0.716709 9.84171 1.03329 9.84171 1.22855 9.64645L4.64645 6.22855C4.84171 6.03329 5.15829 6.03329 5.35355 6.22855L8.77145 9.64645C8.96671 9.84171 9.28329 9.84171 9.47855 9.64645L9.64645 9.47855C9.84171 9.28329 9.84171 8.96671 9.64645 8.77145L6.22855 5.35355C6.03329 5.15829 6.03329 4.84171 6.22855 4.64645L9.64645 1.22855Z" fill="#919191"/>
                </svg>
              </span>
              <a class="employee-outer-box" href="${Routing.generate('app_task_show', { teamId, id: item.taskId })}">
                <div class="message-icon">
                  <span class="${NOTIFICATION_ICON_MAPPER[item.eventType || '1']}"></span>
                </div>
                <div class="message-text">
                    ${!item.isSeen ? `<span class="task-mark pink">${Translator.trans('global.new')}</span>` : ''}
                    ${item.message}
                    <div class="notification-date">${moment.unix(item.createdAt).locale(Translator.locale).fromNow()}</div>
                </div>
              </a>
            </div>
          `)
          })

          $notificationsBox.css({ minHeight: $notificationsBox.height() })

          if (notifications.length < 20 || $notificationsBox.find('.event-row').length === total) {
            $this.remove()
          }

          bindHeaderCalendarBox()
        }
      } catch (error) {} //eslint-disable-line
    })

    this.initCalendars()
    this.approveDeclineEvents()
    this.initializeValidation()
  }

  async fetchNotifications(requestParams) {
    const { request } = useHttp()
    try {
      const response = await request(Routing.generate('app_ajax_notifications', requestParams), 'GET')

      return response
    } catch (error) {} //eslint-disable-line
  }

  async hideNotification(requestParams) {
    const { request } = useHttp()
    try {
      await request(Routing.generate('app_ajax_notification_seen', requestParams), 'GET')
    } catch (error) {} //eslint-disable-line
  }

  async hideAllNotifications() {
    const { request } = useHttp()
    try {
      await request(Routing.generate('app_ajax_notification_seen_all'), 'GET')
    } catch (error) {} //eslint-disable-line
  }

  async removeNotification(requestParams) {
    const { request } = useHttp()
    try {
      await request(Routing.generate('app_ajax_notification_remove', requestParams), 'GET')
    } catch (error) {} //eslint-disable-line
  }

  async removeAllNotifications() {
    const { request } = useHttp()
    try {
      await request(Routing.generate('app_ajax_notification_remove_all'), 'GET')
    } catch (error) {} //eslint-disable-line
  }

  initializeValidation() {
    if (this.$form.length) {
      this.pristine = new Pristine(this.$form.get(0))
    }
  }

  approveDeclineEvents() {
    this.$document.on('click', '.event-approve', (e) => {
      e.preventDefault()

      const $this = $(e.target)
      const eventId = $this.data('event-id')

      if (eventId) {
        this.approve(eventId, $this)
      }
    })

    this.$document.on('click', '.event-decline', (e) => {
      e.preventDefault()

      const $this = $(e.target)
      const eventId = $this.data('event-id')

      if (eventId) {
        this.decline(eventId, $this)
      }
    })
  }

  initCalendars() {
    const self = this

    const onDateSelect = function (selectedDate, input) {
      if (input.id === 'event_startDate') {
        self.$endDate.datepicker('option', 'minDate', selectedDate)
      } else {
        self.$startDate.datepicker('option', 'maxDate', selectedDate)
      }
    }

    const datepickerConfiguration = {
      todayHighlight: true,
      setDate: new Date(),
      onSelect: onDateSelect,
    }

    this.$startDate.datepicker(datepickerConfiguration)
    this.$endDate.datepicker(datepickerConfiguration)

    localeDatepicker()

    if (queryParams().startDate) {
      const startDate = queryParams().startDate

      this.$startDate.datepicker(
        'setDate',
        $.datepicker.formatDate($.datepicker._defaults.dateFormat, new Date(startDate))
      )
      this.$endDate.datepicker(
        'setDate',
        $.datepicker.formatDate($.datepicker._defaults.dateFormat, new Date(startDate))
      )
    } else {
      if (this.$startDate.length && this.$endDate.length) {
        const startDateArray = this.$startDate.val().split('/')
        const endDateArray = this.$endDate.val().split('/')

        this.$startDate.datepicker(
          'setDate',
          $.datepicker.formatDate(
            $.datepicker._defaults.dateFormat,
            new Date(startDateArray[2], startDateArray[1] - 1, startDateArray[0])
          )
        )
        this.$endDate.datepicker(
          'setDate',
          $.datepicker.formatDate(
            $.datepicker._defaults.dateFormat,
            new Date(endDateArray[2], endDateArray[1] - 1, endDateArray[0])
          )
        )
      }
    }
  }

  approve(eventId, button) {
    $.ajax({
      type: 'POST',
      url: Routing.generate('app_event_approve', { eventId: eventId }),
      dataType: 'JSON',
      success: function (data) {
        if (data.success) {
          if (data.location) {
            window.location.href = data.location
          }
        }
      },
    })
  }

  decline(eventId, button) {
    const self = this

    $.ajax({
      type: 'POST',
      url: Routing.generate('app_event_decline', { eventId: eventId }),
      dataType: 'JSON',
      success: function (data) {
        if (data.success) {
          if (typeof button !== 'undefined') {
            button.closest('.event-row').fadeOut(300, function () {
              $(this).remove()
            })
          }
          setTimeout(function () {
            self.updateStatusIcon()
          }, 1000)
        }
      },
    })
  }

  updateStatusIcon() {
    if ($('#header-calendar-box .event-row').length === 0) {
      $('#header-calendar-box .calendar-active').switchClass('calendar-active', 'calendar')
    }
  }

  update(eventId, startDate, endDate) {
    $.ajax({
      type: 'POST',
      url: Routing.generate('app_event_dates', { eventId: eventId }),
      data: { startDate: startDate, endDate: endDate },
      dataType: 'JSON',
    })
  }
}

import Layout from '@core/Layout'
import Event from './Event'

export default class EventsCalendar extends Layout {
  constructor() {
    super({
      pageType: 'events-calendar',
      selector: '.event-calendar-box',
    })

    this.$calendar = this.$target.find('#calendar')
    this.event = new Event()
    this.init()
  }

  init() {
    if (this.$calendar.length) {
      if (['ru', 'pl', 'is', 'lv', 'uk', 'ee', 'lt', 'es'].includes(Translator.locale)) {
        const loadScript = () => {
          return new Promise((resolve, reject) => {
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = `https://cdnjs.cloudflare.com/ajax/libs/fullcalendar/2.2.5/lang/${Translator.locale}.js`
            document.head.append(script)

            script.onload = () => {
              resolve()
            }
          })
        }

        loadScript().then(() =>
          this.initializeCalendar({
            locale: Translator.locale,
          })
        )
      } else {
        this.initializeCalendar()
      }
    }
  }

  initializeCalendar(additionalSettings) {
    const self = this

    this.$calendar.fullCalendar({
      viewRender: function (view, element) {
        element.find('.fc-day-number').each(function () {
          $(this).append(
            '<a href="' +
              Routing.generate('app_events_add', {
                teamId: userTeamId,
                startDate: $(this).data('date'),
              }) +
              '" class="event-page-link"><span class="icon-plus"></span></a>'
          )
        })
      },
      header: {
        left: '',
        center: 'prev,title,next',
        right: '',
      },
      buttonIcons: {
        prev: 'left-single-arrow',
        next: 'right-single-arrow',
      },
      navLinks: true,
      editable: true,
      eventLimit: true,
      events: function (start, end, timezone, callback) {
        self.list(start.unix(), end.unix(), callback)
      },
      eventDrop: function (event, delta, revertFunc) {
        if (confirm('Are you sure about this change?')) {
          self.event.update(event.id, event.start.format('DD/MM/YYYY'), event.end.format('DD/MM/YYYY'))
        } else {
          revertFunc()
        }
      },
      eventResize: function (event, delta, revertFunc) {
        if (confirm('is this okay?')) {
          self.event.update(event.id, event.start.format('DD/MM/YYYY'), event.end.format('DD/MM/YYYY'))
        } else {
          revertFunc()
        }
      },
      ...additionalSettings,
    })
  }

  list(startDate, endDate, callback) {
    const self = this

    $.ajax({
      type: 'POST',
      url: Routing.generate('app_events_load'),
      data: { startDate: startDate, endDate: endDate },
      dataType: 'JSON',
      success: function (data) {
        if (data.success && data.events) {
          callback(data.events)

          self.event.approveDeclineEvents()
        }
      },
    })
  }
}
